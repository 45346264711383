@font-face {
  font-family: 'press start';
  src: local('press start'), url(fonts/pressstart2p-webfont.woff) format('woff');
}

body {
  margin: 0;
  font-size: 19px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'press start', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

.game-title {
  font-size: 0.9rem;
}